.navbar {
  padding: 0px 5px 0px 5px !important;
  border-bottom: solid 1px #e8e8e8;
  height: 85px;
  z-index: 100;
  position: fixed !important;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
}

.navbar-content {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1260px;
}

.navbar-menu {
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center-link {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 297px;
  height: 24px;
  left: calc(50% - 297px / 2 + 0.5px);

  .extra-link {
    height: 24px;
    color: #414d5c;
    /* Inside Auto Layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 40px;
    text-decoration: none;
    right: 100px;
    font-family: var(--graphik);
    font-weight: bold;

    &:hover {
      color: #154e01;
    }
  }
}

.icon-dropdown-menu {
  position: static;
  width: 16px;
  height: 16px;
  font-weight: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
}

/* .ant-dropdown-menu-item {
  padding: 14px 28px !important;
  font-size: 16px !important;
  font-family: var(--graphik);
} */

.ant-divider-horizontal {
  width: 90% !important;
  min-width: 90% !important;
}

.ant-dropdown-menu-item-group-title {
  padding: 0 !important;

  .divider {
    text-align: center;
    /* color: $grey-400; */
  }

  .menu-item-group-wrapper {
    margin: auto;
  }

  .ant-menu-submenu .ant-menu-submenu-popup .ant-menu .ant-menu-light .ant-menu-submenu-hidden .ant-menu ant-menu-sub .ant-menu-vertical {
    border-radius: 10%;
  }

  .navbar {
    .logo {
      position: relative;
      width: 63.02px;
      height: 42px;
      left: 8px;
      top: 20px;
    }

    .logo-text {
      /* Techsphere */
      position: relative;
      width: 138px;
      height: 26px;
      /* left: 135px; */
      top: 20px;

      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 26px;

      color: #000000;
    }

    li .ant-menu-item,
    .ant-menu-item:hover,
    .ant-menu-item-only-child {
      text-decoration: none;
      position: static;
      /* body */
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      /* Inside Auto Layout */
      flex: none;
      order: 0;
      flex-grow: 1;
    }

    .navbar-nav {
      align-items: center;
    }

    .ham-btn {
      margin-left: auto;
      height: 100%;
      display: none;
      background: none;
    }
  }

  .mobile-dropdown {
    display: none;
    visibility: hidden;
  }

  .ant-divider {
    text-align: center;
    background-color: #cdd3d9;
  }

  .navbar .navbar-content .navbar-menu {
    justify-content: space-between;
  }

  @media (max-width: 1028px) {
    .logo-text {
      transition: ease-out 2s;
      display: none;
    }

    .navbar .navbar-content .navbar-menu {
      >* {
        right: 110px;

        &:not(:last-child)>* {
          margin: 0px 12px;
        }
      }
    }

    .navbar .navbar-content .dropdown-menu-container {
      right: 24px;
    }
  }

  @media (max-width: 768px) {
    .logo-text {
      display: none;
    }

    .navbar .navbar-content .navbar-menu {
      >* {
        right: 110px;
        font-size: 14px;

        &:not(:last-child)>* {
          margin: 0px 12px;
          font-size: 14px;
        }
      }
    }

    .navbar .navbar-content .dropdown-menu-container {
      right: 24px;
    }
  }

  @media (max-width: 718px) {
    .mobile-dropdown {
      display: list-item;
      visibility: visible;
    }

    .navbar .navbar-content .navbar-menu,
    .logo-text {
      display: none;
    }
  }
}

.navbar-dropdown {
  .ant-btn {
    width: 80px;
    height: 40px;
    padding: 4px 12px 2px 2px;
    background: #fff;
    border-radius: 44px;
    border: 1.5px solid #cdd3d9;
  }

  /* .ant-btn:hover {
    border: 1.5px solid $grey-900 !important;
  } */

  .navbar-extra-link-right {
    /* @extend .body;
    @extend .semi-bold;
    color: $grey-600; */
    /* Become a mentor */
    text-decoration: none;
    margin-right: 12px;

    &:hover {
      color: var(--primary);
    }

    /* @media screen and (max-width: 425px) {
      display: none;
    } */
  }

  .user-icon {
    width: 30px;
    height: 30px;
    margin-left: 2px;
    margin-top: 2px;
    color: #858d95;
  }

  .navbar-icon-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 3px;
    margin-top: -5px;
  }

  .menu-toggle-icon {
    width: 20px;
    height: 20px;
    padding-top: 5px;
    color: #21252a;
    vertical-align: -4px !important;
  }
}

.navbar-dropdown-menu {
  border-radius: 8px;
  margin-top: 20px;
  width: 256px;
}

.navbar-dropdown-user-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 32px;
  margin-left: 28px;
  margin-bottom: 24px;
}

.navbar-active-highlight {
  color: #154e01;
}