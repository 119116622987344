.dne-page-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 100px auto auto;

  .dne-page-container {
    max-width: 500px;
    background-color: inherit;

    .dne-page-title {
      /* @extend .semi-bold; */
      color: var(--primary) !important;
    }

    /* .dne-page-subtitle {
      @extend .body;
    } */

    .dne-page-go-home-button-container {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      color: white;
      background-color: var(--primary);
      margin-top: 30px;
    }

    .dne-page-go-home-button-containerLhover {
      color: white !important;
      background-color: var(--primary);
      border: 1px solid var(--primary) !important;
    }
  }
}