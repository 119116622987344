@import url('../components/RecipeDetail.css');

.gallery-ctn {
  margin-top: 12vh;
  width: 100vw;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
  padding: 20px;
}

.search-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 200%; */
}

.search-bar input {
  padding: 10px;
  margin-bottom: 20px;
  width: 80vw;
  max-width: 90vw;
  border: 1px solid black;
  border-radius: 10px;
}

/* input {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.data-list {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-bottom: 4vh;
}

.data-item {
  /* background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 80%;
  max-width: 300px;
  text-align: left; */
  flex: 1 1 calc(25% - 16px);
  /* 33.333% width with margin gap considered */
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 25px;
  /* Optional: border around each container */
  padding: 16px;
  text-align: center;
  background-color: #f4f4f4;
  max-width: 22.25vw;
  color: #173F4E;
  /* Optional: background color */
}

.data-item img {
  /* max-width: 200px; */
  max-width: 20vw;
}

.data-item:hover {
  opacity: 50%;
  background-color: #154e01;
  cursor: pointer;
}

@media (max-width: 600px) {
  .data-item {
    width: 90%;
  }
}

.collapsible {
  padding: 10px;
  max-width: 200px;
  align-items: center;
}

.duo-col-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
