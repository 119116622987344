.bundle-list {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  margin-top: 12vh;
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  margin-left: 10vw;
  margin-right: 10vw;
}

.bundle-item {
  /* background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 80%;
  max-width: 300px;
  text-align: left; */
  flex: 1 1 calc(33.333% - 2vw);
  /* 33.333% width with margin gap considered */
  box-sizing: border-box;
  border: 1px solid var(--primary);
  border-radius: 25px;
  /* Optional: border around each container */
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  max-width: 30vw;
  /* position: relative; */
  /* Optional: background color */
}

.bundle-item img {
  max-width: 200px;
}

.bundle-btn {
  /* display: inline-block; */
  /* position: absolute;
  bottom: 5%; */
  background-color: var(--primary);
  border-radius: 25px;
}

.bundle-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.bundle-label {
  color: var(--primary);
}

.bundle-desc {
  text-align: justify;
  color: black;
  font-weight: normal;
}