@font-face {
  font-family: 'Graphik-400-Regular';
  src: url(./assets/Graphik-400-Regular.otf);
}

@font-face {
  font-family: 'Graphik-700-Bold';
  src: url(./assets/Graphik-700-Bold.otf);
}

@font-face {
  font-family: 'Graphik-600-Semibold';
  src: url(./assets/Graphik-600-Semibold.otf);
}

:root {
  --graphik: 'Graphik-400-Regular', sans-serif;
  --graphik-bold: 'Graphik-700-Bold', sans-serif;
  --graphik-600-semibold: 'Graphik-600-Semibold', sans-serif;
  --primary: #173F4E;
  --secondary: #A65111;
}

header {
  font-family: var(--graphik);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: var(--graphik);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
