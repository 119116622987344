.contact-form-container {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 12vh;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  font-family: var(--graphik);
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  height: 100px;
  resize: none;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  opacity: 50%;
  cursor: pointer;
}

.form-label {
  color: var(--primary);
}

.form-desc {
  color: #555555;
}