.data-item-detail {
  width: 60vw;
  height: auto;
  margin-top: 10vh;
  margin-left: 20vw;
  margin-bottom: 5vh;
  border: 1px solid black;
  font-family: var(--graphik);
}

.duo-col-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt-col {
  flex: 2;
  padding: 20px;
}

.img-col { 
  flex: 1;
  padding: 20px;
  margin-right: 4vw;
  text-align: right;
}

.img-col img {
  /* max-height: 400px; */
  height: auto;
}

.itm-ttl {
  text-align: center;
}

.rtn-btn {
  max-width: 80px;
  font-size: 14px;
}

.section-title {
  margin-left: 1vw;
}

.section-header {
  margin-left: 2vw;
  color: var(--primary);
  font-weight: bold;
}

.item-lst {
  margin-left: 2vw;
  max-width: 54vw;
  text-align: justify;
}

.tags {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 0.5em;
  /* border: 1px solid black; */
  max-width: 60vw;
}

.tag {
  /* border: 1px solid white; */
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  font-size: 14px;
  background-color: var(--secondary);
  color: white;
  font-weight: 400;
}